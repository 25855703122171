import { globalCss } from "srs.sharedcomponents/lib/esm/styles";
export const globalStyles = globalCss({
  "*": {
    textDecoration: "none",
    border: "none",
    padding: 0,
    margin: 0,
  },
  a: {
    textDecoration: "inherit !important",
  },
});